import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fetchPartner, updatePartnerStatus } from "../../../Api/v1/leads/Api";

export default function PartnerDetails({ status }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noofRecords, setNoOfRecords] = useState(0);
  const state = useSelector((state) => state);

  const fetchData = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    const response = await fetchPartner(token, status);
    if (response && response.statusCode === 200) {
      setNoOfRecords(response.data.total_count);
      if (response.data.partner_details.length > 0) {
        setData(response.data.partner_details);
      } 
    }
    setLoading(false);
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [status]);


  const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const handleUpdateStatus = async (partnerId, newStatus) => {
    setLoading(true);
    const token = state.auth.auth.token;
    let payload = {
      "_id": partnerId,
      "status": newStatus
    }
    const response = await updatePartnerStatus(payload, token);
    if (response && response.statusCode === 200) {
      toast.success("Status updated successfully!");
      setData([])
      fetchData(0, true);
    } else {
      toast.error("Failed to update status.");
    }
    setLoading(false);
  };

  const handleStatusChange = (e, partnerId) => {
    const newStatus = e.target.value;
    handleUpdateStatus(partnerId, newStatus);
  };

  const columns = [
    { name: "First Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Last Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Mobile Number", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Email Id", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Occupation", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "State", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "District", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Submitted On", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "Update Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const partnerId = data[tableMeta.rowIndex]._id;
          const currentStatus = data[tableMeta.rowIndex].status;
          return (
            <select
              value={currentStatus}
              onChange={(e) => handleStatusChange(e, partnerId)}
              className="form-select"
            >
              <option value="New">New</option>
              <option value="Review">Review</option>
              <option value="Pending">Pending</option>
              <option value="Rejected">Rejected</option>
              <option value="Converted">Converted</option>
            </select>
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
            body: {
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        {loading && <Spinner />}
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`All ${capitalizeFirstLetter(status)} Partner (${noofRecords})`}
            data={data.map(item => [
              item.first_name,
              item.last_name,
              item.mobile_number,
              item.email_id,
              item.occupation,
              item.state,
              item.district,
              new Date(item.createdAt).toLocaleString()
            ])}
            columns={columns}
            options={options}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </ThemeProvider>
        <ToastContainer />
      </div>
    </div>
  );
}
