import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";


import {  GetUser,  GetContactList, GetSpecificContactList, UpdateContactUs } from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Spinner from "../../../Spinner/Spinner";

import MUIDataTable from "mui-datatables";
import CustomModal from "../../../Components/CustomModal";

export default function ContactUsers() {
 
  const [selectedTab, setSelectedTab] = React.useState("Open");
  const AllTabs = ["Open","In Progress","Closed"];

  
  const [contactTask, setContactTask] = useState(true);
  const [modalContact, setModalContact] = useState(false);

  const [loading, setLoading] = useState(true);

  const [getContact, setGetMyContact] = useState([]);

  const state = useSelector((state) => state);

  //getlead
  const GetContactTsks = async (status, value) => {
    setLoading(true);

    let data = await GetContactList(status);
    if (data && data.statusCode === 200) {
      let data1 = data?.data.filter((e) => e.customPackage === value);
      let temp = [];
      data1.map((data, i) => {
        let dummy = {
          _id: data?._id,
          name: data?.name,
          emailId: data?.emailId,

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          mobileNumber: data?.mobileNumber,
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,

          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });

      setGetMyContact(temp);
      setLoading(false);
    } else {
      setGetMyContact([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

    const [getMyContact1, setGetContactTask1] = useState({});

    const GetContactTsks1 = async (id) => {
    setLoading(true);
    
    let data = await GetSpecificContactList(id);
    if (data && data.statusCode === 200) {
      
      setGetContactTask1(data.data);
      setLoading(false);
    } else {
      setGetContactTask1([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  

  
  const [contactprofile, setContactProfileDetails] = useState([]);
  async function dataContactProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getContactBasicDetails = await GetUser(token, id);
    if (getContactBasicDetails && getContactBasicDetails?.statusCode === 200) {
      setContactProfileDetails(getContactBasicDetails.data);
      setLoading(false);
    } else {
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => { 
    window.scroll(0, 0);

   
    GetContactTsks("open");
    dataContactProfile();
  }, []);


  
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmitUpdate = async (e, value, id,value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
   
  
    const response = await UpdateContactUs(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      GetContactTsks(value1);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

 

  const handleSubmitRead = async (e, id) => {
    e.preventDefault();
    let Payload;
   
      Payload = {
        _id: id,
        messageRead: "read",
      };
   
    const token = state.auth.auth.token;
    const response = await UpdateContactUs(Payload, token);
    if (response && response.statusCode === 200) {
      GetContactTsks("open");
    } else {
      //console.log("error");
    }
  };


  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  GetContactTsks1(tableMeta.rowData[0]);
                  handleSubmitRead(val, tableMeta.rowData[0]);
                  setModalContact(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "status",
      label: " Select the Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <select
              className="form-control form-control-lg"
              defaultValue={value}
              onChange={(e) => {
                handleSubmitUpdate(
                  e,
                  e.target.value,
                  tableMeta.rowData[0],
                  value
                );
              }}
            >
              <option value="open">Open</option>
              <option value="inprogress">In Progress</option>
              <option value="closed">Closed</option>
            </select>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "messageRead",
      label: "messageRead",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[6]==="unread") {
        return {
          style: { background: "#DBDFEA" , cursor:"pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor:"pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {contactTask ? (
              <div>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Contact us form</h5>
                  <div>
                    {/* <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setContactTask(false);
                      }}
                    >
                      Create lead
                    </button> */}
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (tab === "In Progress") {
                                      GetContactTsks("inprogress");
                                    } else if (tab === "Closed") {
                                      GetContactTsks("closed");
                                    } else if (tab === "Open") {
                                      GetContactTsks("open");
                                    }
                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - Contact Users "}
                    data={getContact}
                    columns={columns}
                    options={options}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        <CustomModal
          open={modalContact}
          onClickOutside={() => {
            setModalContact(false);
          }}
        >
          <div className="modal-content w-50 m-auto">
            <div className="modalContact-body p-0 ">
              <div className="container ">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{
                    marginRight: "30px",
                    marginTop: "50px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  onClick={() => {
                    setModalContact(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="col-lg-10">
                              <div className="mb-5">
                                <h4 className="fw-bold text-center  mt-2">
                                  User Details
                                </h4>
                              </div>
                              <div>
                                <div className="mb-3">
                                  <h6 for="" className="form-label mb-3">
                                    Name : {getMyContact1?.name}
                                  </h6>
                                  <h6 for="" className="form-label mb-3">
                                    Email ID : {getMyContact1?.emailId}
                                  </h6>
                                  <h6 for="" className="form-label mb-3">
                                    Mobile Number : {getMyContact1?.mobileNumber}
                                  </h6>
                                  <h6 for="" className="form-label mb-3">
                                    Question : {getMyContact1?.question}
                                  </h6>
                                  <h6 for="" className="form-label mb-3">
                                    Subject : {getMyContact1?.subject}
                                  </h6>
                                  <h6 for="" className="form-label mb-3">
                                    Created At :{" "}
                                    {moment(getMyContact1?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
