import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import { GetSpecificHistory, GetUser, getUserHistory } from "../../../Api/Api";
import moment from "moment";
import Spinner from "../../../Spinner/Spinner";
import CustomModal from "../../../Components/CustomModal";
import MUIDataTable from "mui-datatables";
import { schemes_request_transactions_list_url } from "../../../Api/APIUrl";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
export default function History() {
  const [selectedTaskListTab, setSelectedTaskListTab] =
    React.useState("Schemes Purchase");
  const AllTabs = ["Schemes Purchase", "Service", "Package", "Custom"];
  const [loading, setLoading] = useState(true);
  const [specificHistoryModal, setSpecificHistoryModal] = useState(false);
  const state = useSelector((state) => state);
  const token = state.auth.auth.token;
  const [history, setHistory] = useState([]);

  const [schemePurchaseHistory, setSchemePurchaseHistory] = useState([]);
  async function fetchData(type) {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await getUserHistory(token, type);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      let temp = [];
      getBasicDetails.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          price: data?.price,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          paymentInfo: data?.paymentInfo?.razorpay_payment_id,
          name: data?.userId?.name,
          phoneNumber: data?.userId?.phoneNumber,
          paymentID: data?.paymentInfo?.tracking_id,
          orderID: data?.orderId,
          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setHistory(temp);
      setLoading(false);
    } else {
      setHistory([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }


  const [specificHistory, setSpecificHistory] = useState([]);

  async function fetchDataSpecificHistory(id) {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetSpecificHistory(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setSpecificHistory(getBasicDetails.data);
      setLoading(false);
    } else {
      setSpecificHistory([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  const [profileDetail, setProfileDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setProfileDetails(getBasicDetails.data);
      setLoading(false);
    } else {
      setProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    // fetchData("All");
    fetchSchemesPurchase();
    fetchDataProfile();
  }, []);

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "orderID",
      label: "Order ID",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                {value ? value : "-"}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "paymentID",
      label: "Payment ID",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
              // onClick={(val) => {
              //   fetchDataSpecificHistory(tableMeta.rowData[0]);
              //   setSpecificHistoryModal(true);
              // }}
              >
                {value ? value : "-"}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
    },
    {
      name: "createdAt",
      label: "Payment Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              {value}
              {/* <div>{moment(value).format("DD/MM/YYYY")}</div>
              <p className="mb-0 small">
                At {moment(value).format("h:mm:ss a")}
              </p> */}
            </div>
          );
        },
      },
    },

    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => {
          return <div>₹{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  const columnsSchema = [
    {
      name: "order_id",
      label: "Order ID",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "scheme_title",
      label: "Scheme Title",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "user_name",
      label: "User Name",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "user_email",
      label: "User Email",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "user_mobile",
      label: "User Mobile",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "total_amount",
      label: "Total Amount",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) =>
          value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' }),
      }
    },
    {
      name: "payment_status",
      label: "Payment Status",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      }
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => new Date(value).toLocaleString(),
      }
    },
  ];

  const getRowProps = (rowData) => {
    const { payment_status } = rowData.data;
    return {
      style: {
        backgroundColor: payment_status === 'Paid' ? '#dff0d8' : '#f2dede', // Light green for Paid, light red for Not Paid
        color: payment_status === 'Paid' ? '#3c763d' : '#a94442' // Dark green text for Paid, dark red text for Not Paid
      }
    };
  };

  const fetchSchemesPurchase = async () => {
    const response = await fetchWithQueryAPI(token, `${schemes_request_transactions_list_url}?status=all`);

    if (response && response?.statusCode === 200 && response.data) {
      setSchemePurchaseHistory(response.data);
    }
  };

  return (
    <div>
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="">
              <h5 className="mb-0 fw-bold color-maroon">Purchased History</h5>
            </div>
            <div className="my-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 px-0">
                    <div className="tab-section">
                      <ul className="ps-0">
                        {AllTabs.map((tab, index) => {
                          return (
                            <div
                              key={index}
                              className={
                                selectedTaskListTab === tab
                                  ? "serviceTab active"
                                  : "serviceTab inactive"
                              }
                              onClick={() => {
                                if (tab === "Service") {
                                  fetchData("service");
                                } else if (tab === "Package") {
                                  fetchData("package");
                                } else if (tab === "Custom") {
                                  fetchData("custom-package");
                                } else if (tab === "Schemes Purchase") {
                                  fetchSchemesPurchase();
                                }
                                setSelectedTaskListTab(tab);
                              }}
                            >
                              <li>
                                <span className="align-middle">{tab}</span>
                              </li>
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{height:"200px"}}>  */}
              {
                selectedTaskListTab === "Schemes Purchase" ?
                  <MUIDataTable
                    title={"Transaction Data"}
                    data={schemePurchaseHistory?.transaction_data}
                    columns={columnsSchema}
                    options={{
                      getRowProps: getRowProps,
                      // other options...
                    }}
                  /> :
                  <MUIDataTable
                    title={"Dalmaf - Purchase History"}
                    data={history}
                    columns={columns}
                    options={options}
                  />
              }

            </div>
            <CustomModal
              open={specificHistoryModal}
              onClickOutside={() => {
                setSpecificHistoryModal(false);
              }}
            >
              <div className="modal-content w-50 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      // style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}

                      onClick={() => {
                        setSpecificHistoryModal(false);
                      }}
                    >
                      close
                    </span>

                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                {/* {specificHistory?.map((e, index) => ( */}

                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div className="mt-3 ">
                                      {/* <h6 className="color-maroon fw-bold text-center">

                                {specificHistory?.servicesRequestId?.servicesTitle}

                              </h6> */}

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Payment Id:
                                        </h6>{" "}
                                        {
                                          specificHistory?.paymentInfo
                                            ?.razorpay_payment_id
                                        }
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Transfer Id:
                                        </h6>{" "}
                                        {specificHistory?.transferId}
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Purchase Type:
                                        </h6>{" "}
                                        {specificHistory?.purchaseType}
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Price :
                                        </h6>{" "}
                                        {specificHistory?.price}
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Purchase Type:
                                        </h6>{" "}
                                        {specificHistory?.purchaseType}
                                      </p>
                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Price :
                                        </h6>{" "}
                                        {specificHistory?.price}
                                      </p>
                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Package Info :
                                        </h6>{" "}
                                        {specificHistory?.PackageInfo}
                                      </p>
                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          status:
                                        </h6>{" "}
                                        {specificHistory?.status}
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          createdAt:
                                        </h6>{" "}
                                        {moment(
                                          specificHistory.createdAt
                                        ).format("DD/MM/YYYY")}
                                      </p>

                                      <p className="small">
                                        <h6 className="color-maroon fw-bold">
                                          Updated At:
                                        </h6>{" "}
                                        {moment(
                                          specificHistory.updatedAt
                                        ).format("DD/MM/YYYY")}
                                      </p>
                                    </div>{" "}
                                  </div>
                                </div>

                                {/* ))} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </>
        )}
      </div>
    </div>
  );
}
