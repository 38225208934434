import React, { useEffect, useState } from "react";
import AboutImg1 from "../../Assets/Images/aboutpage-1.webp";
import NewLog from "../../Assets/dalmaf-vertical-logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import { GetMain, GetUser, UseraboutContent } from "../../Api/Api";
import { useSelector } from "react-redux";
import ServicesAllpages from "../ServicesAllpages";
import { useNavigate } from "react-router-dom";

export default function AboutContent() {

  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const [contentDetail, setContentDetails] = useState({});

  const [contentValidation, setContentValidation] = useState({});

  const setContentDetailsValue = (key, value) => {
    setContentDetails({ ...contentDetail, [key]: value });
    if (contentValidation[key]) delete contentValidation[key];
  };

  const setcontentValidationValue = (key, value) => {
    setContentValidation({ ...contentValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [userContent, setUserProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    GetMyTsks();
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      setUserProfile([]);

      //console.log("error while getting user profile info");
    }
  };

  //console.log(userContent);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(contentDetail?.name);
    validate.emailId = EmailValidation(contentDetail?.emailId?.toLowerCase());
    validate.subject = NonEmptyValidation(contentDetail?.subject);
    validate.question = NonEmptyValidation(contentDetail?.question);
    validate.mobileNumber = PhoneNumberValidation(contentDetail?.mobileNumber);
    validate.servicesType = NonEmptyValidation(contentDetail?.servicesType);

    setContentValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        emailId: contentDetail.emailId,
        name: contentDetail.name,
        subject: contentDetail.subject,
        question: contentDetail.question,
        mobileNumber: contentDetail.mobileNumber,
        servicesType: contentDetail.servicesType,
      };
      const response = await UseraboutContent(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.location.reload();
        setContentDetails({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
    // }
  };
  const state = useSelector((state) => state);

  const [main, setMain] = useState([]);
  const GetMyTsks = async () => {
    // const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data]; //.sort((a, b) => b.title < a.title);
      setMain(numAscending);
    } else {
      setMain([]);
      //console.log("Error while getting my task");
    }
  };

  return (
    <>
      <ToastContainer />
      {/* <div className="footer-wrapper footer-layout1">
        <div class="footer-top">
              <div class="logo-bg"></div>
              <div class="container">
                  <div class="row align-items-center">
                      <div class="col-xl-3">
                          <div class="footer-logo">
                            <a  href="" onClick={(e)=>{e.preventDefault();navigate("/")}}>
                              <span data-mask-src={NewLog} class="mask-icon"></span>
                              <img src={NewLog} alt="Webteck"/>
                            </a>
                          </div>
                      </div>
                      <div class="col-xl-9">
                          <div class="footer-contact-wrap">
                              <div class="footer-contact">
                                  <div class="footer-contact_icon"><i class="fas fa-phone"></i></div>
                                  <div class="media-body"><span class="footer-contact_text">Quick Call Us:</span> <a
                                          href="tel:+19088000393" class="footer-contact_link">+190-8800-0393</a></div>
                              </div>
                              <div class="footer-contact">
                                  <div class="footer-contact_icon"><i class="fas fa-envelope"></i></div>
                                  <div class="media-body"><span class="footer-contact_text">Mail Us On:</span> <a
                                          href="mailto:info@webteck.com" class="footer-contact_link">info@webteck.com</a>
                                  </div>
                              </div>
                              <div class="footer-contact">
                                  <div class="footer-contact_icon"><i class="fas fa-location-dot"></i></div>
                                  <div class="media-body"><span class="footer-contact_text">Visit Location:</span> <a
                                          href="https://www.google.com/maps" class="footer-contact_link">54 Flemington,
                                          USA</a></div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
      </div> */}
      <div class="space" data-bg-src="img/bg/why_bg_1.png">
        <div class="container">
          <div class="row align-items-center flex-row-reverse">
            <div class="col-xxl-7 col-xl-6 mb-30 mb-xl-0">
              <div class="img-box2">
                <div class="img1"><img src="img/normal/why_1_1.jpg" alt="Why"/></div>
              </div>
            </div>
            <div class="col-xxl-5 col-xl-6">
              <div class="title-area mb-35"><span class="sub-title">
                <div class="icon-masking me-2"><span class="mask-icon"
                  data-mask-src="img/theme-img/title_shape_1.svg"></span> <img
                    src="img/theme-img/title_shape_1.svg" alt="shape"/></div>WHY CHOOSE US
              </span>
                <h2 class="sec-title">We Deal With The Aspects Professional <span class="text-theme">Audit & Legal</span> Services</h2>
              </div>
              {/* <p class="mt-n2 mb-30">Collaboratively envisioneer user friendly supply chains and cross unit
                imperative. Authoritativel fabricate competitive resource and holistic.</p> */}
              <div class="two-column">
                <div class="checklist style2">
                  <ul>
                    <li><i class="far fa-check"></i> Legal Services</li>
                    <li><i class="far fa-check"></i> Auditing Services</li>
                    <li><i class="far fa-check"></i> Human Resources</li>
                  </ul>
                </div>
                {/* <div class="checklist style2">
                  <ul>
                    <li><i class="far fa-check"></i> Bank Auction Guidance</li> 
                    <li><i class="far fa-check"></i> Buying and Selling Assistance</li>
                     <li><i class="far fa-check"></i> Marketing Assistance</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="overflow-pages">
        <div className="about-banner py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="about-content">
                  <h2 className="banner-heading after-line mb-5">
                    About Our Company
                  </h2>
                  <p>
                    We are happy to Introduce the{" "}
                    <span className="fw-bold">Dalmaf</span> Company, the
                    one-stop destination for Legal, Auditing, Bank Auction
                    Support, Buying and Selling, and Marketing Assistance.
                    Founded in June of 2022 as a Global Market Pioneer for
                    offering comprehensive company solutions in all aspects, Our
                    team of Professionals is Passionate about aiding businesses
                    in Resolving Difficulties, Strengthening their Internal
                    Operations, and Realising their ultimate goal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">Legal Services</h4>
                    <p>
                      At Dalmaf, we acknowledge the importance of legal
                      compliance as well as risk governance in today's
                      competitive business environment. Our experienced legal
                      team offers counsel on a wide range of legal
                      complications, such as contract drafting and review,
                      intellectual property protection, regulatory compliance,
                      and dispute resolution. We coordinate closely with our
                      clients to fulfil their statutory obligations efficiently
                      and effectively.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">Auditing Services</h4>
                    <p>
                      At Dalmaf, Financial transparency and clarity are crucial
                      for the success of any company. Our auditing solutions
                      guarantee that your financial records and statements are
                      extensively reviewed and assessed in order to provide
                      shareholders, investors, and other stakeholders with
                      accurate assurance. Our independent auditors conduct
                      comprehensive audits, discover areas for growth in
                      internal control systems, risk mitigation, and operational
                      effectiveness, and offer strategic suggestions on how to
                      improve your financial processes.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">Human Resources</h4>
                    <p>
                      At Dalmaf, we provide exhaustive manpower solutions that
                      are tailored to your specific requirements. Managing human
                      resources is a crucial aspect of managing a successful
                      business. From acquiring talent as well as recruitment to
                      performance management and employee development, our human
                      resources specialists offer invaluable assistance in
                      optimising your workforce. We intend to align the goals of
                      your company's human resources with its long-term goals by
                      growing a productive and engaged workforce.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">
                      Bank Auction Guidance
                    </h4>
                    <p>
                      At Dalmaf, we deliver committed guidance as well as
                      expertise in banking bidding processes. Whether you have
                      an interest in taking part in auctions or purchasing
                      auctioned properties or assets, our crew of experts offers
                      comprehensive guidance for guaranteeing a successful
                      auction experience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">
                      Buying and Selling Assistance
                    </h4>
                    <p>
                      At Dalmaf, we deliver assistance with all aspects of
                      buying and selling, helping you to make well-informed
                      choices and improve deals. From market research and
                      evaluation of prices to bargaining and handling
                      agreements, our team of professionals provides
                      comprehensive support at every step of the entire buying
                      and selling process. Successful buying and selling
                      procedures are vital for a business's success.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div>
                    <h4 className="color-maroon fw-bold">
                      Marketing Assistance
                    </h4>
                    <p>
                      At Dalmaf, we understand that successful advertising is
                      the key to generating revenue for your company while
                      developing an effective brand position. Consequently, we
                      deliver extensive marketing assistance customised
                      according to your specific requirements. With our
                      expertise and systematic strategy, we can assist you in
                      achieving your intended audience, boosting your brand's
                      visibility, and accomplishing your marketing goals.
                    </p>
                    <p>
                      We are committed to offering outstanding customer service
                      with absolute professionalism and integrity at Dalmaf
                      Company. Our team of professionals tackles every project
                      with comprehensive expertise in the sector, decades of
                      experience, and a client-focused mindset. We acknowledge
                      that each organisation has unique demands, so we tailor
                      our services to meet your specific requirements and
                      provide bespoke solutions for guaranteeing your business's
                      achievement.
                    </p>
                    <p>
                      Feel the satisfaction of having a trusted ally for all of
                      your Legal, Audit, Human Resource, Bank Auction
                      Assistance, Buying and Selling and Marketing Assistance
                      demands when you collaborate with the Dalmaf Company.
                    </p>
                    <p>
                      Contact us immediately in order to find out how we might
                      assist you in reaching your business goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="client-left-section py-5">
          <div className="container my-4">
            <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
            <div className="row">
              <div className="col-lg-6 col-md-12" data-aos="fade-left">
                <div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                    width="100%"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-6 col-md-12" data-aos="fade-left">

                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Your Name"
                        aria-invalid={Boolean(
                          contentValidation?.name?.status === false
                        )}
                        value={contentDetail?.name}
                        onChange={(e) => {
                          setContentDetailsValue("name", e.target.value);
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "name",
                            StringValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <small className="text-danger">
                        {contentValidation?.name?.message
                          ? `Name ${contentValidation?.name?.message}`
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">
                        Mobile No <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        className="form-control form-control-lg"
                        placeholder="Enter Your Mobile No"
                        aria-invalid={Boolean(
                          contentValidation?.mobileNumber?.status === false
                        )}
                        value={contentDetail?.mobileNumber}
                        onChange={(e) => {
                          setContentDetailsValue(
                            "mobileNumber",
                            e.target.value
                          );
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "mobileNumber",
                            PhoneNumberValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <small className="text-danger">
                        {contentValidation?.mobileNumber?.message
                          ? `Mobile Number ${contentValidation?.mobileNumber?.message}`
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">
                        E- Mail ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        placeholder="Enter Your E- Mail ID"
                        aria-invalid={Boolean(
                          contentValidation?.emailId?.status === false
                        )}
                        value={contentDetail?.emailId}
                        onChange={(e) => {
                          setContentDetailsValue("emailId", e.target.value);
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "emailId",
                            EmailValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <small className="text-danger">
                        {contentValidation?.emailId?.message
                          ? `Email id ${contentValidation?.emailId?.message}`
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="mb-4">
                      <label className="form-label ">
                        Subject <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Your  Subject "
                        aria-invalid={Boolean(
                          contentValidation?.subject?.status === false
                        )}
                        value={contentDetail?.subject}
                        onChange={(e) => {
                          setContentDetailsValue("subject", e.target.value);
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "subject",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <small className="text-danger">
                        {contentValidation?.subject?.message
                          ? `Subject ${contentValidation?.subject?.message}`
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-4">
                      <label className="form-label">Service Type</label>
                      <select
                        className="form-control form-control-lg"
                        value={contentDetail?.servicesType}
                        onChange={(e) => {
                          setContentDetailsValue(
                            "servicesType",
                            e.target.value
                          );
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "servicesType",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                      >
                        <option value="">---Select Service Type---</option>
                        <option value="Legal">Legal</option>
                        <option value="Audit">Audit</option>
                        <option value="Manpower">Manpower</option>
                        <option value="Auction">Auction</option>
                        <option value="Buy/Sell">Buy/Sell</option>
                      </select>

                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-4">
                      <label className="form-label">
                        Questions <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows="6"
                        className="form-control"
                        placeholder="Enter Questions"
                        aria-invalid={Boolean(
                          contentValidation?.question?.status === false
                        )}
                        value={contentDetail?.question}
                        onChange={(e) => {
                          setContentDetailsValue("question", e.target.value);
                        }}
                        onBlur={(e) => {
                          setcontentValidationValue(
                            "question",
                            NonEmptyValidation(e.target.value)
                          );
                        }}
                        autoComplete="off"
                      />
                      <small className="text-danger">
                        {contentValidation?.question?.message
                          ? `Question ${contentValidation?.question?.message}`
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="button"
                      className="btn login-btn w-100"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="number-counter py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6" data-aos="fade-in">
                <div className="counter-content text-start">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h1 className="mb-0">
                        <span className="material-symbols-rounded align-middle about-call filled-icon">
                          call
                        </span>
                      </h1>
                    </div>
                    <div className="">
                      <h5 className="fs-6 mb-0">Toll Free</h5>
                      <h5 className="mb-0 fs-4">1800-309-8445</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" data-aos="fade-in">
                <div className="counter-content text-start">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h1 className="mb-0">
                        <span className="material-symbols-rounded align-middle about-call filled-icon">
                          mail
                        </span>
                      </h1>
                    </div>
                    <div className="">
                      <h5 className="fs-6 mb-0">Email ID</h5>
                      <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" data-aos="fade-in">
                <div className="counter-content text-start">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h1 className="mb-0">
                        <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                      </h1>
                    </div>
                    <div className="">
                      <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                      <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <ServicesAllpages />
        </div>
      </div> */}
    </>
  );
}
