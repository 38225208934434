import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { fetchWithoutTokenAPI, fetchWithQueryAPI, updateAPI } from "../../../Api/v1/Util/Api";
import { find_all_user_details_url, schemes_request_find_all_url, schemes_request_update_url } from "../../../Api/APIUrl";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export default function AdminSideListComponent({ statusTypeProps, labelProps }) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [empData, setEmpData] = useState([]);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const token = state.auth.auth.token;

  const handleStatusChange = (e, _id) => {
    const newStatus = e.target.value;
    const payload = {_id:_id,status:newStatus};
    updateStatus(payload, "Status updated successfully", "Failed to update the status")
    // Call an API to update the status (optional, based on your requirements)
  };
  const handleStatusEmpChange = (e, _id) => {
    const empId = e.target.value;
    let payload = { _id:_id,assigned_to: empId }
    updateStatus(payload,"Employee mapped successfully", "Failed to map the employee");
  };

  const fetchEmployee = async () => {
    const response = await fetchWithoutTokenAPI(find_all_user_details_url + "?token=" + token + "&role=subadmin&status=active");
    if (response && response.statusCode === 200) {
      if (response.data) {
        setEmpData(response.data);
      }
    }
  };

  const fetchData = async () => {
    const response = await fetchWithQueryAPI(token, schemes_request_find_all_url + "?status=" + statusTypeProps);
    if (response && response.statusCode === 200) {
      if (response.data) {
        setData(response.data.scheme_request);
      }
    }
  };

  const updateStatus = async (payload,successMsg,errorMsg) => {
    const response = await updateAPI(token, schemes_request_update_url,payload );
    if (response && response.statusCode === 200) {
      toast.success(successMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
      });
      fetchData()
    }else{
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
    fetchEmployee();
  }, [statusTypeProps]);

  const columns = [
    {
      name: "scheme_title",
      label: "Scheme Name",
      options: {
        setCellProps: () => ({ style: { textAlign: 'left' } }),
        customBodyRender: (value, tableMeta) => (
          <span
            onClick={() => navigate(`/scheme-details/${value}/${data[tableMeta.rowIndex]._id}`)}
            style={{ color: "#007bff", textDecoration: "none", cursor: "pointer" }}
          >
            {value}
          </span>
        )
      }
    },
    {
      name: "user_name",
      label: "Name",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta) => (
          <span style={{ cursor: "pointer", color: "blue" }}>
            {value}
          </span>
        )
      }
    },
    { name: "user_email_id", label: "Email", options: { setCellProps: () => ({ style: { textAlign: 'left' } }) } },
    { name: "user_email_phone_number", label: "Phone", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "payment_status", label: "Payment Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "assigned_to_name", label: "Employee Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY, h:mm A"),
      }
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY, h:mm A"),
      }
    },
    // {
    //   name: "status",
    //   label: "Update Status",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const partnerId = data[tableMeta.rowIndex]._id;
    //       const currentStatus = data[tableMeta.rowIndex].status;
    //       const isAssigned = data[tableMeta.rowIndex].assigned_to_name; // Check if assigned_to_name exists

    //       return (
    //         state.auth.auth.role !== "user" ? (
    //           <FormControl variant="outlined" fullWidth>
    //             <InputLabel>Status</InputLabel>
    //             <Select
    //               value={currentStatus}
    //               onChange={(e) => handleStatusChange(e, partnerId)}
    //               label="Status"
    //             >
    //               <MenuItem value="un_assigned" disabled>
    //                 Unassigned
    //               </MenuItem>
    //               <MenuItem value="assigned" disabled={!isAssigned}>
    //                 Assigned
    //               </MenuItem>
    //               <MenuItem value="to_do">To Do</MenuItem>
    //               <MenuItem value="inprogress">In Progress</MenuItem>
    //               <MenuItem value="hold">Hold</MenuItem>
    //               <MenuItem value="terminated">Terminated</MenuItem>
    //               <MenuItem value="completed">Completed</MenuItem>
    //             </Select>
    //           </FormControl>
    //         ) : (
    //           statusOptions[currentStatus] || currentStatus
    //         )
    //       );
    //     }
    //   }
    // },
    // {
    //   name: "assigned_to",
    //   label: "Assign Employee",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       const partnerId = data[tableMeta.rowIndex]._id;
    //       const currentAssignedTo = data[tableMeta.rowIndex].assigned_to_name || "";

    //       return (
    //         <FormControl variant="outlined" fullWidth>
    //           <InputLabel>Employee</InputLabel>
    //           <Select
    //             value={currentAssignedTo}
    //             onChange={(e) => handleStatusEmpChange(e, partnerId)}
    //             label="Employee"
    //           >
    //             {empData.map(emp => (
    //               <MenuItem key={emp._id} value={emp._id}>
    //                 {emp.name}
    //               </MenuItem>
    //             ))}
    //           </Select>
    //         </FormControl>
    //       );
    //     }
    //   }
    // }
  ];

  const statusOptions = {
    all: "All",
    un_assigned: "Unassigned",
    assigned: "Assigned",
    to_do: "To Do",
    inprogress: "In Progress",
    hold: "On Hold",
    terminated: "Terminated",
    completed: "Completed",
  };

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '14px',
            },
            body: {
              textAlign: 'center',
              fontSize: '14px',
            },
          },
        },
      },
    });

  return (
    <div >
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={`${labelProps} Plans Request (${data?.length || 0})`}
          data={data.map(item => [
            item.scheme_title,
            item.user_name,
            item.user_email_id,
            item.user_email_phone_number,
            item.payment_status === "not_paid" ? "Not Paid" : "Paid",
            item.assigned_to_name || "NA",
            item.createdAt || "NA",
            item.updatedAt || "NA",
            statusOptions[item.status] || item.status,  // Map the status to display labels
          ])}
          columns={columns}
          options={options}
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            border: "1px solid #ddd",
          }}
        />
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}
