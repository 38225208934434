import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { find_all_user_details_url, schemes_request_comments_add_url, schemes_request_comments_find_all_url, schemes_request_find_particular_url, schemes_request_payment_initiate_url, schemes_request_update_url } from "../../../Api/APIUrl";
import { useParams } from "react-router-dom";
import { createAPI, fetchWithoutTokenAPI, fetchWithQueryAPI, updateAPI } from "../../../Api/v1/Util/Api";
import swal from 'sweetalert';

const SpecificRequestComponent = () => {
  const [data, setData] = useState({});
  const [status, setStatus] = useState("");
  const [empData, setEmpData] = useState([]);
  const [assignedEmployee, setAssignedEmployee] = useState("");
  const [comment, setComment] = useState("");
  const [typeOfComment, setTypeOfComment] = useState("ordinary");
  const [ordinaryComments, setOrdinaryComments] = useState([]);
  const [holdComments, setHoldComments] = useState([]);
  const [resolutionComments, setResolutionComments] = useState([]);
  const [terminationComments, setTerminationComments] = useState([]);
  const [activeTab, setActiveTab] = useState("ordinary");
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(null);

  const state = useSelector((state) => state);
  const token = state.auth.auth.token;
  const { id } = useParams();
  const fetchData = async () => {
    const response = await fetchWithQueryAPI(token, `${schemes_request_find_particular_url}?_id=${id}`);
    if (response && response.statusCode === 200) {
      if (response?.data) {
        setData(response?.data);
        setStatus(response?.data?.status);
        if (response?.data?.status === "completed")
          setActiveTab("resolution")
        else if (response?.data?.status === "terminated")
          setActiveTab("termination")
        else if (response?.data?.status === "hold")
          setActiveTab("hold")
        setAssignedEmployee(response?.data?.assigned_to_name)
      }
    }
  };
  const fetchEmployee = async () => {
    const response = await fetchWithoutTokenAPI(find_all_user_details_url + "?token=" + token + "&role=subadmin&status=active");
    if (response && response.statusCode === 200) {
      if (response.data) {
        setEmpData(response.data);
      }
    }
  };
  useEffect(() => {
    fetchData();
    fetchEmployee();
    fetchComments();
  }, []);

  const handleAddComment = async () => {
    if (comment.trim()) {
      let payload = {
        scheme_request_id: id,
        type_of_comment: typeOfComment,
        comment: comment
      }
      const response = await createAPI(token, schemes_request_comments_add_url, payload);
      if (response && response.statusCode === 200) {
        setComment('')
        fetchComments()
        if (['hold', 'termination', 'resolution'].includes(typeOfComment)) {

          await updateStatus(pendingStatus, "Status updated successfully", "Failed to update the status");
          setTypeOfComment("ordinary")
          setPendingStatus({})
          fetchData();
          setCommentModalOpen(false)

        }
      }
    }
  };
  const fetchComments = async () => {
    const response = await fetchWithQueryAPI(token, `${schemes_request_comments_find_all_url}?scheme_request_id=${id}`);

    if (response && response?.statusCode === 200 && response.data) {
      // Filter comments based on their type
      const ordinary = response?.data?.comments?.filter(comment => comment.type_of_comment === "ordinary");
      const hold = response?.data?.comments?.filter(comment => comment.type_of_comment === "hold");
      const resolution = response?.data?.comments?.filter(comment => comment.type_of_comment === "resolution");
      const termination = response?.data?.comments?.filter(comment => comment.type_of_comment === "termination");

      // Update state with the filtered comments
      setOrdinaryComments(ordinary);
      setHoldComments(hold);
      setResolutionComments(resolution);
      setTerminationComments(termination);
    }
  };
  const handleStatusChange = async (e, _id) => {
    const newStatus = e.target.value;
    const payload = { _id: _id, status: newStatus };
    if (['hold', 'terminated', 'completed'].includes(newStatus)) {
      if (newStatus === "completed") {
        setTypeOfComment("resolution")
      } else if (newStatus === "hold") {
        setTypeOfComment("hold")
      } else if (newStatus === "terminated") {
        setTypeOfComment("termination")
      }
      setPendingStatus({ _id, status: newStatus });
      setCommentModalOpen(true);
      return;
    }

    // Confirmation dialog with SweetAlert
    const confirmation = await swal({
      title: "Are you sure?",
      text: "Do you really want to update the status?",
      icon: "warning",
      buttons: ["Cancel", "Yes, update it!"],
      dangerMode: true,
    });

    if (confirmation) { // If confirmed
      await updateStatus(payload, "Status updated successfully", "Failed to update the status");
    }
  };
  const handleStatusEmpChange = async (e, _id) => {
    const empId = e.target.value;
    const payload = { _id: _id, assigned_to: empId };

    // Confirmation dialog with SweetAlert
    const confirmation = await swal({
      title: "Are you sure?",
      text: "Do you really want to assign this employee?",
      icon: "warning",
      buttons: ["Cancel", "Yes, assign!"],
      dangerMode: true,
    });

    if (confirmation) { // If confirmed
      await updateStatus(payload, "Employee mapped successfully", "Failed to map the employee");
    }
  };
  const updateStatus = async (payload, sucessMSG, errorMSG) => {
    const response = await updateAPI(token, schemes_request_update_url, payload);
    if (response && response.statusCode === 200) {
      toast.success(sucessMSG, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
      });
      fetchData();
    } else {
      toast.error(errorMSG, {
        position: toast.POSITION.TOP_RIGHT,
        className: "toast-message",
        autoClose: 5000,
      });
    }
  };
  const makePayment = async () => {
    let payload = {
      reference_id: id,
      return_url: window.location.href,
    }
    const response = await createAPI(token, schemes_request_payment_initiate_url, payload);
    const confirmation = await swal({
      title: "Confirm Status Update",
      text: `You're about to proceed with a payment update. The total amount is ₹${data.monthly_amount.toLocaleString()} + ₹${data.gst_amount.toLocaleString()} (GST). Would you like to continue?`,
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          closeModal: true,
          className: "",
          style: {
            backgroundColor: "#f8d7da",
            color: "#721c24",
            fontWeight: "bold",
            padding: "10px 20px",
            borderRadius: "5px",
          },
        },
        confirm: {
          text: "Yes, Proceed with Payment",
          value: true,
          visible: true,
          closeModal: true,
          className: "",
          style: {
            backgroundColor: "#28a745",
            color: "#fff",
            fontWeight: "bold",
            padding: "10px 20px",
            borderRadius: "5px",
          },
        },
      },
      dangerMode: true,
    });
    if (confirmation) {
      if (response && response.statusCode === 200) {
        window.location.href = response?.data?.url
      }
    }
  };

  const statusMapping = [
    { key: "all", show: "All" },
    { key: "un_assigned", show: "Unassigned" },
    { key: "assigned", show: "Assigned" },
    { key: "to_do", show: "To Do" },
    { key: "inprogress", show: "In Progress" },
    { key: "hold", show: "On Hold" },
    { key: "terminated", show: "Terminated" },
    { key: "completed", show: "Completed" }
  ];

  const tabs = [
    { key: "ordinary", label: "Comments", data: ordinaryComments },
    { key: "hold", label: "Hold Comments", data: holdComments },
    { key: "resolution", label: "Resolution Comments", data: resolutionComments },
    { key: "termination", label: "Termination Comments", data: terminationComments },
  ];

  // Function to find the display name for the status
  const getStatusDisplayName = (status) => {
    const statusObj = statusMapping.find(item => item.key === status);
    return statusObj ? statusObj.show : status;
  };

  const styles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000, // Ensures the modal appears on top
    },
    modalContent: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '800px',
      width: '100%',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
    textarea: {
      width: '100%',
      height: '100px',
      margin: '10px 0',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #ddd',
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: '#007BFF',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginRight: '10px',
      fontWeight: 'bold',
    },
    cancelButton: {
      padding: '10px 20px',
      backgroundColor: '#ccc',
      color: '#333',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  return (
    <>
      <div style={{ margin: "0 auto", padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <span
          style={{
            // float:'right',
            // display: "flex",
            alignItems: "center",
            padding: "5px 10px",
            backgroundColor: "#f0f0f0",
            borderRadius: "5px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => window.location.href = "/schemes-Request"}
        >
          <span style={{ marginRight: "5px" }}>←</span> {/* Icon */}
          Back
        </span>

        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2 style={{ color: "#333" }}>Scheme Request Details</h2>
          <div style={{ display: "flex", gap: "10px" }}>
            <span
              style={{
                padding: "5px 10px",
                backgroundColor: status === "Completed" ? "#28a745" : "#007BFF",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              {getStatusDisplayName(status)}
            </span>
            <span
              style={{
                padding: "5px 10px",
                backgroundColor: data.payment_status === "not_paid" ? "#dc3545" : "#28a745",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              {data.payment_status === "not_paid" ? "Not Paid" : "Paid"}
            </span>
            <span
              style={{
                padding: "5px 10px",
                backgroundColor: "red",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
            >
              {data.scheme_title}
            </span>
            {(data.payment_status === "not_paid" && state.auth.auth.role === "user") ? <button className="th-btn shadow-none" style={{ padding: '5px 10px' }} onClick={makePayment}> Make Payment</button> : ""}
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "20px" }}>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Name </strong> {data.user_name}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Email Id </strong> {data.user_email_id}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Mobile Number </strong> {data.user_email_phone_number}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Scheme Name </strong> {data.scheme_title}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Executive Name </strong> {data.assigned_to_name || "NA"}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Created At</strong> {new Date(data.createdAt).toLocaleString()}
          </div>
          <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
            <strong>Updated At</strong> {new Date(data.updatedAt).toLocaleString()}
          </div>
          {
            (state.auth.auth.role === "admin" || state.auth.auth.role === "superadmin" || state.auth.auth.role === "subadmin") ?
              <>
                <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
                  <strong>Status</strong>
                  <select
                    value={status}
                    onChange={(e) => handleStatusChange(e, id)}
                    style={{ marginLeft: "10px", padding: "5px", borderRadius: "5px", border: "1px solid #ccc" }}
                  >
                    {[
                      { key: "all", show: "All" },
                      { key: "un_assigned", show: "Unassigned" },
                      { key: "assigned", show: "Assigned" },
                      { key: "to_do", show: "To Do" },
                      { key: "inprogress", show: "In Progress" },
                      { key: "hold", show: "On Hold" },
                      { key: "terminated", show: "Terminated" },
                      { key: "completed", show: "Completed" }
                    ].map((statusOption) => (
                      <option key={statusOption.key} value={statusOption.key}>
                        {statusOption.show}
                      </option>
                    ))}
                  </select>
                </div>
                {(state.auth.auth.role === "admin" || state.auth.auth.role === "superadmin") ?
                  <div style={{ flex: "1 1 45%", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "5px" }}>
                    <strong>Assign Employee</strong>
                    <select
                      value={assignedEmployee} // Bind to the assignedEmployee state
                      onChange={(e) => handleStatusEmpChange(e, id)} // Call the function with the event and item ID
                      style={{ marginLeft: "10px", padding: "5px", borderRadius: "5px", border: "1px solid #ccc" }}
                    >
                      <option value="">Select Employee</option>
                      {empData.map((employee) => (
                        <option key={employee._id} value={employee._id}> {/* Use employee.id as value */}
                          {employee.name} {/* Display employee name */}
                        </option>
                      ))}
                    </select>
                  </div> : ""}
              </> : ""
          }
        </div>
        <div style={{
          flex: "1 1 45%",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          color: "#333"
        }}>
          <h3 style={{
            marginBottom: "15px",
            color: "#333",
            fontSize: "1.2rem",
            borderBottom: "2px solid #00bfa5",
            paddingBottom: "10px"
          }}>
            Service Included in this Scheme
          </h3>
          <div style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            fontSize: "1rem",
            alignItems: "center"
          }}>
            {data?.scheme_sub_levels?.map((service, index) => (
              <React.Fragment key={index}>
                <span style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "4px 8px",
                  borderRadius: "5px",
                  backgroundColor: "#00bfa5",
                  color: "#fff",
                  fontSize: "0.9rem"
                }}>
                  {service.title}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>



        <div style={{ marginTop: "20px" }}>
          <label style={{ fontWeight: "bold", marginBottom: "5px", display: "block" }}>Add Comment:</label>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter comment"
            style={{
              padding: "8px",
              width: "100%",
              fontSize: "16px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              minHeight: "80px",
            }}
          ></textarea>
          <button
            onClick={handleAddComment}
            className="th-btn shadow-none"
          >
            Add Comment
          </button>
        </div>



        <div>
          {/* Tabs */}
          <div style={{ display: 'flex', borderBottom: '1px solid #ddd', marginTop: '20px' }}>
            {tabs.map(
              (tab) =>
                tab.data && tab.data.length > 0 && (
                  <button
                    key={tab.key}
                    onClick={() => setActiveTab(tab.key)}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      backgroundColor: activeTab === tab.key ? "#007BFF" : "#f0f0f0",
                      color: activeTab === tab.key ? "#fff" : "#333",
                      border: "none",
                      borderBottom: activeTab === tab.key ? "3px solid #007BFF" : "none",
                    }}
                  >
                    {tab.label}
                  </button>
                )
            )}
          </div>

          {/* Tab Content */}
          <div style={{ padding: "20px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
            {tabs.map((tab) =>
              activeTab === tab.key && tab.data && (
                <div key={tab.key}>
                  <ul style={{ listStyle: "none", padding: "0" }}>
                    {tab.data.map((c, index) => (
                      <li
                        key={index}
                        style={{
                          padding: "15px",
                          borderBottom: "1px solid #ddd",
                          marginBottom: "10px",
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          boxShadow: "0 1px 5px rgba(0, 0, 0, 0.1)"
                        }}
                      >
                        <p style={{ margin: "0", color: "#888", fontSize: "0.9em" }}>{new Date(c.createdAt).toLocaleString()}</p>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                          <img
                            src={c.commented_by_profile_pic || "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"}
                            alt="Commenter"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              marginRight: "10px",
                              objectFit: "cover"
                            }}
                          />
                          <div>
                            <p style={{ margin: "0", fontWeight: "bold", fontSize: "1em" }}>{c.commented_by}</p>
                            <p style={{ margin: "0", color: "#555", fontSize: "0.9em" }}>{c.commented_by_designation}</p>
                          </div>
                        </div>
                        <p style={{ marginTop: "10px", color: "#333", lineHeight: "1.5" }}>{c.comment}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>

        </div>


        <ToastContainer />
      </div>
      {isCommentModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2 style={{ textAlign: 'left' }}>{getStatusDisplayName(pendingStatus.status)} status Comment</h2>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your comment here"
              style={styles.textarea}
            ></textarea>
            <button onClick={() => { setCommentModalOpen(false); setTypeOfComment("ordinary") }} className="btn">
              Cancel
            </button>
            <button onClick={handleAddComment} className="th-btn shadow-none" style={{ padding: '10px', minWidth: '70px' }}>
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SpecificRequestComponent;
