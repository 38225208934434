export const convert_to_client="/v1/leads/convert_to_client"

export const lead_overall_dashboard = "/v1/leads/overall/dashboard/count"
export const lead_specific_dashboard = "/v1/leads/specific/dashboard/count"

export const investors_submit_url ="/v1/investors/submit"
export const investors_find_status_url ="/v1/investors/find"
export const investors_update_status_url ="/v1/investors/update"

export const lead_fetch ="/v1/leads/find"
export const lead_fetch_with_status ="/v1/leads/find?status="
export const lead_fetch_particular ="/v1/leads/find_particular?_id="
export const lead_update ="/v1/leads/update"

export const contacts_delete = "/v1/contacts/delete";
export const contacts_create ="/v1/contacts/create"
export const contacts_update ="/v1/contacts/update"
export const contacts_fetch_by_reference ="/v1/contacts/find?reference_id="
export const contacts_fetch_by_find_particular ="/v1/contacts/find_particular?_id="

export const notes_create ="/v1/notes/create"
export const notes_fetch_by_find_particular="/v1/notes/find_particular?_id="
export const notes_fetch_by_reference ="/v1/notes/find?reference_id="
export const notes_update="/v1/notes/update"

export const task_fetch ="/v1/task/find"
export const task_fetch_by_reference ="/v1/task/find?reference_id="
export const task_fetch_by_find_particular ="/v1/task/find_particular?_id="
export const task_create ="/v1/task/create"
export const task_update ="/v1/task/update"
export const task_comment_add = "/v1/task/comments/submit"
export const task_comment_find = "/v1/task/comments/find?task_id="

export const estimate_fetch_by_reference ="/v1/estimation/find?reference_id="
export const estimate_create ="/v1/estimation/create"

export const attendance_current_status ="/v1/attendance/current_status"
export const attendance_current_submit ="/v1/attendance/submit"
export const attendance_all_employee_listing = "/v1/attendance/all_employee_listing"
export const attendance_overall_employee_listing ="/v1/attendance/overall_employee_listing"
export const attendance_overall_specific_employee_listing ="/v1/attendance/specific_employee_listing"


export const auction_user_findProperty_url ="/user/findProperty"
export const auction_user_findOneTwoWheelerDetails_url ="/user/findOneTwoWheelerDetails"
export const auction_user_findOneGoldAuctionDetails_url ="/user/findOneGoldAuctionDetails"
export const auction_payment_initiate ="/auction/payment_initiate"
export const auction_payment_status ="/auction/find_payment_status?order_id="

export const auction_purchase_history_admin_url ="/auction/transaction_list?user_id=all&status=all"

export const dashboard_revenue_count_admin_url ="/dashboard/revenue_count?filter_by=custom"
export const dashboard_revenue_history_admin_url ="/v1/dashboard/revenue_history?filter_by=custom"
export const attendance_automatic_clockout_list_admin_url ="/v1/attendance/automatic_clockout/list"
export const attendance_dashboard_statistics_list_admin_url ="/v1/attendance/dashboard/statistics"

export const schemes_fetch_all_url ="/v1/user/schemes/find"
export const schemes_find_particular_all_url ="/v1/user/schemes/find_particular"
export const schemes_user_scheme_request_submitted_url ="/v1/user/scheme/request_submitted"
export const schemes_request_find_all_url ="/v1/scheme/request/find_all"
export const schemes_request_find_particular_url ="/v1/scheme/request/find_particular"
export const schemes_request_update_url ="/v1/scheme/request/update"

export const schemes_request_comments_add_url ="/v1/scheme_request/comments/add"
export const schemes_request_comments_find_all_url ="/v1/scheme_request/comments/find_all"
export const schemes_request_payment_initiate_url ="/v1/user/scheme_request/payment_initiate"
export const schemes_request_transactions_list_url ="/v1/scheme_request/transactions/list"

export const find_all_user_details_url ="/findAllUserDetails"




// Specific employee listing api Api URL : http://localhost:8004/dalmaf/v1/attendance/specific_employee_listing?employee_id=66b51ef1dba627c780f2ebed&filterBy=custom&fromDate=2024-08-08&toDate=2024-08-11

export const generic_reference_id = "reference_id=";
export const generic_status = "status=";
export const generic_type ="&type="
export const generic_is_client ="is_client="
export const generic_pagination_start = "&start="
export const generic_pagination_limit = "&limit="
export const generic_specific_date = "specific_date="
export const generic_specific_month = "specific_month="
export const generic_employee_id = "employee_id="
export const generic_filterBy = "filterBy="
export const generic_fromDate = "fromDate="
export const generic_toDate ="toDate="

export const from_date = "from_date=";
export const to_date = "to_date=";
