import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AdminNavbar from "../../../Admin/Component/Navbar/AdminNavbar";
import SchemesRequestListComponent from "../../../Components/v1/Schemes/SchemesRequestListComponent";

export default function SchemesRequestList() {
  const [value, setValue] = React.useState('all');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const filter = [
    { key: "all", show: "All" },
    { key: "un_assigned", show: "Unassigned" },
    { key: "assigned", show: "Assigned" },
    { key: "to_do", show: "To Do" },
    { key: "inprogress", show: "In Progress" },
    { key: "hold", show: "On Hold" },
    { key: "terminated", show: "Terminated" },
    { key: "completed", show: "Completed" }
  ];
  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      {/* <Box> */}
      <div className="main">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Schedule Meeting Details"
          TabIndicatorProps={{
            style: {
              backgroundColor: "#dc3545", // Highlight active tab with a red underline
            },
          }}
          textColor="inherit"
          // centered
        >
          {filter.map((item) => (
            <Tab
              key={item.key}
              label={item.show}
              value={item.key}
              sx={{
                color: value === item.key ? "#dc3545" : "#000", // Active tab color change
                fontWeight: value === item.key ? "bold" : "normal",
                backgroundColor: value === item.key ? "#fff2f2" : "transparent", // Light background for active tab
                borderRadius: "5px", // Rounded edges for tabs
                padding: "10px 20px", // Better padding for tabs
                transition: "0.3s ease", // Smooth transition
                "&:hover": {
                  color: "#dc3545", // Red color on hover for non-active tabs
                  backgroundColor: "#f9f9f9", // Light gray on hover
                },
              }}
            />
          ))}
        </Tabs>

        {/* <div className="main"> */}
          <SchemesRequestListComponent
            statusTypeProps={value}
            labelProps={filter.find((item) => item.key === value)?.show} // Pass the label as a prop
          />
        </div>
      {/* </Box> */}

    </div>
  );
}