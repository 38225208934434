import React, { useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RevenueCountDashboard from "./RevenueCountDashboard";
import RevenueHistoryDashboard from "./RevenueHistoryDashboard";
import { Tab, Nav } from 'react-bootstrap';

const RevenueDashboard = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('count');

  return (
    <>
      <AdminNavbar title={"Revenue Count Dashboard"} />
      <div className="main">
        {(state?.auth?.auth?.role === "superadmin" || state?.auth?.auth?.role === "admin")? (
          <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="count">Revenue Count</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="history">Revenue History</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="count">
                <RevenueCountDashboard />
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <RevenueHistoryDashboard />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <p>Unauthorized Access</p>
        )}
      </div>
    </>
  );
};

export default RevenueDashboard;
