import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PartnerDetails from "./PartnerDetails";
import FreelauncerDetails from "./FreelauncerDetails";

export default function Freelauncer() {
  const [value, setValue] = React.useState('New');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
      {/* <Box> */}
        <Tabs value={value} onChange={handleChange} aria-label="Auction Leads">
          <Tab label="New" value="New" />
          <Tab label="Reivew" value="Review" />
          <Tab label="Pending" value="Pending" />
          <Tab label="Rejected" value="Rejected" />
          <Tab label="Approved" value="Converted" />
        </Tabs>
        {/* <div className="main" style={{ marginTop: "50px" }}> */}
          <FreelauncerDetails status={value} />
        {/* </div> */}
      {/* </Box> */}
      </div>
    </div>
  );
}
