import React, { useEffect, useState } from 'react'
import { GetAllEmployeeRole, GetAllSubAdminAndAdmin, leaveSpecific, UpdateLeave } from '../../../Api/Api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Profile from "../../../Assets/Images/navbar-img/f1.webp";
import moment from 'moment';
import AdminNavbar from '../Navbar/AdminNavbar';
import Spinner from '../../../Spinner/Spinner';
import { showErrorMsg, showSuccessMsg } from '../../../Store/util';

const LeaveEdit = () => {
  const { id } = useParams()
  const [specificleaveList, setSpecificleaveList] = useState({});
  useEffect(() => {

    GetAllSubadmins();
    getListEmployeeRole()
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    fetchDataProfile()
  }, [id])

  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const state = useSelector((state) => state);
  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const role = "subadmin";
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token, role, "active");
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      console.error("Error while getting all subadmins");
    }
  };
  const fetchDataProfile = async () => {
    const token = state?.auth?.auth?.token;
    const response = await leaveSpecific(token, id);
    if (response && response?.statusCode === 200) {
      setSpecificleaveList(response?.data);
    } else {
      setSpecificleaveList({});
      //console.log("error while getting user profile info");
    }
  };
  const getListEmployeeRole = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, "active");
    if (response.statusCode === 200) {
      setRoleList(response.data);
      setLoading(false);
    } else {
      setRoleList([]);
      setLoading(false);
    }
  };

  const [specificValue, setSpecificValue] = useState('')
  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: specificValue,
      approvedBy: state?.auth?.auth?._id,
    };
    if(specificValue.length){
      const token = state?.auth?.auth?.token;
      const response = await UpdateLeave(Payload, token);
      if (response && response.statusCode === 200) {
        showSuccessMsg(response.message);
        fetchDataProfile()
      } else {
        showErrorMsg(response.message || response.error.response.data.message);
        //setfailureModal(true);
      }
    }else{
      showErrorMsg("Select Either Approve or Reject");
    }

    
  };
  return (
    <div>


      <AdminNavbar />

      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>

            <div className="row d-flex align-items-center justify-content-center mt-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="card shadow-sm mt-4 mb-3 px-3 py-4">
                      <div className="row justify-content-center mt-2">
                        <div className="col-lg-11">
                          <div className="mb-4">
                            <h4 className="fw-bold mt-2 text-center">Application Details</h4>
                          </div>
                          <div className="mb-3">
                            <div className="d-flex align-items-center">
                              <img
                                className="border"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                                src={specificleaveList?.userimage || Profile}
                                alt="User"
                              />
                              <div className="ms-3">
                                <h5 className="mb-1">{specificleaveList?.username}</h5>
                                <h6 className="fw-bold text-muted">
                                  {roleList
                                    ?.filter((e) => e._id === specificleaveList?.userDesignation)
                                    .map((i) => i?.title)}
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div className="table-responsive">
                            <table className="table table-borderless">
                              <tbody>
                                <tr>
                                  <th scope="row" className="text-muted">Leave Type</th>
                                  <td>
                                    <span className="badge bg-success p-2">
                                      {specificleaveList?.type}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className="text-muted">Start Date</th>
                                  <td>
                                    {moment(specificleaveList?.startDate).format("DD/MM/YYYY")}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className="text-muted">End Date</th>
                                  <td>
                                    {moment(specificleaveList?.endDate).format("DD/MM/YYYY")}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" className="text-muted">Reason</th>
                                  <td>{specificleaveList?.content}</td>
                                </tr>
                                <tr>
                                  <th scope="row" className="text-muted">Status</th>
                                  <td className="fw-bold text-capitalize">{specificleaveList?.status}</td>
                                </tr>
                                {specificleaveList?.status === "approved" && (
                                  <tr>
                                    <th scope="row" className="text-muted">Approved By</th>
                                    <td>
                                      <select
                                        className="form-control"
                                        value={specificleaveList?.approvedBy}
                                        disabled
                                      >
                                        {allSubadmin?.map((e) => (
                                          <option key={e._id} value={e._id}>{e?.name}</option>
                                        ))}
                                      </select>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>

                          {(state?.auth?.auth?.role === "admin" || state?.auth?.auth?.role === "superadmin") && (
                            <div className="mb-3 mt-4">
                              <div className="d-flex justify-content-end align-items-center">
                                <select
                                  className="form-select me-2"
                                  onChange={(e) => setSpecificValue(e.target.value)}
                                >
                                  <option value="">Approve/Reject</option>
                                  <option value="approved">Approved</option>
                                  <option value="rejected">Rejected</option>
                                </select>
                                <button
                                  type="button"
                                  onClick={(e) => handleSubmitUpdate(e, specificValue, specificleaveList._id)}
                                  className="btn btn-primary fw-bold"
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </div>
    </div>



  )
}

export default LeaveEdit