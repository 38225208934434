import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { schemes_request_find_all_url } from "../../../Api/APIUrl";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

export default function UserListComponent({ statusTypeProps, labelProps }) {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const token = state.auth.auth.token;

  const fetchData = async () => {
    const response = await fetchWithQueryAPI(token, schemes_request_find_all_url + "?status=" + statusTypeProps);
    if (response && response.statusCode === 200) {
      if (response.data) {
        setData(response.data.scheme_request);
      }
    }
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [statusTypeProps]);

  const userColumns = [
    {
      name: "scheme_title",
      label: "Scheme Title",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta) => (
          <span
            onClick={() => navigate(`/scheme-details/${value}/${data[tableMeta.rowIndex]._id}`)}
            style={{ color: "#007bff", textDecoration: "none", cursor: "pointer" }}
          >
            {value}
          </span>
        )
      }
    },
    { name: "payment_status", label: "Payment Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "assigned_to_name", label: "Assigned To", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY, h:mm A"),
      }
    },
    {
      name: "updatedAt",
      label: "Updated At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY, h:mm A"),
      }
    },
    { name: "status", label: "Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
  ];

  const statusOptions = {
    all: "All",
    un_assigned: "Unassigned",
    assigned: "Assigned",
    to_do: "To Do",
    inprogress: "In Progress",
    hold: "On Hold",
    terminated: "Terminated",
    completed: "Completed",
  };

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '14px',
            },
            body: {
              textAlign: 'center',
              fontSize: '14px',
            },
          },
        },
      },
    });

  return (
    <div >
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={`${labelProps} Plans Request (${data?.length || 0})`}
          data={data.map(item => [
            item.scheme_title,
            item.payment_status === "not_paid" ? "Not Paid" : "Paid",
            item.assigned_to_name || "NA",
            item.createdAt || "NA",
            item.updatedAt || "NA",
            statusOptions[item.status] || item.status,
          ])}
          columns={userColumns}
          options={options}
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            border: "1px solid #ddd",
          }}
        />
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}
