import React, { useEffect, useState } from "react";
import AI1 from "../../../Assets/Images/ai-1.webp";
import AIhover1 from "../../../Assets/Images/ai-hover1.webp";
import Logo from "../../../Assets/dalmaf-vertical-logo.png";

import Swal from "sweetalert2";
import AI3 from "../../../Assets/Images/ai-3.webp";
import AI4 from "../../../Assets/Images/ai-4.webp";
import AIhover3 from "../../../Assets/Images/ai-hover3.webp";
import AIhover4 from "../../../Assets/Images/ai-hover4.webp";
import AddI2 from "../../../Assets/Images/icon/Frame20948.webp";
import Addhover2 from "../../../Assets/Images/icon/Frame209482.webp";
import AddI5 from "../../../Assets/Images/icon/Vecto.webp";
import Addhover5 from "../../../Assets/Images/icon/Vecto2.webp";
import AddI3 from "../../../Assets/Images/icon/Vector.webp";
import Addhover3 from "../../../Assets/Images/icon/Vector2.webp";
import AddI4 from "../../../Assets/Images/icon/eos-icons_role-binding.webp";
import Addhover4 from "../../../Assets/Images/icon/eos-icons_role-binding2.webp";
import AddI1 from "../../../Assets/Images/icon/mdi_account-service.webp";
import Addhover1 from "../../../Assets/Images/icon/mdi_account-service2.webp";
import SIhover1 from "../../../Assets/Images/ser-icon-hover.webp";
import SI1 from "../../../Assets/Images/ser-icon.webp";

import NotificationBadge, { Effect } from "react-notification-badge";
import AddI7 from "../../../Assets/Images/icon/icon-park-solid_log.webp";
import Addhover7 from "../../../Assets/Images/icon/icon-park-solid_log2.webp";
// import AddI8 from "../../../Assets/Images/icon/Vecto.webp";
// import Addhover8 from "../../../Assets/Images/icon/Vecto2.webp";
import { Link, NavLink, useNavigate } from "react-router-dom";


import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNotification,
  GetUser,
  Markread,
  UpdateNotification,
} from "../../../Api/Api";
import { GetInitials } from "../../../Store/Constants";
import { logOutSuccess } from "../../../Store/auth/action";
import { createAPI, fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { attendance_current_status, attendance_current_submit } from "../../../Api/APIUrl";
import { showErrorMsg, showSuccessMsg } from "../../../Store/util";
import { ToastContainer } from "react-toastify";
import { showToastSuccessMsg } from "../../../Store/toastMsg";

export default function AdminNavbarNew({ title }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  function Logout(e) {
    e.preventDefault();
    dispatch(logOutSuccess(state?.auth?.isLoggedIn === false));
    localStorage.clear();
    navigate("/");

    //console.log("Logout function called");
  }


  return (
    <ul>
      {/* Dashboard Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li >Dashboard</li>
        </a>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink1"
        >
          <li>
            <NavLink to="/Dashboard">
              <span className="material-symbols-rounded filled-icon">
                dashboard
              </span>
              <li >Dashboard</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/revenue-dashboard">
              <span className="material-symbols-rounded filled-icon">
                dashboard
              </span>
              <li >Revenue Dashboard</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/attendance-dashboard">
              <span className="material-symbols-rounded filled-icon">
                dashboard
              </span>
              <li >Attendance Dashboard</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Request Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li >Request Management</li>
        </a>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink1"
        >
          <li>
            <NavLink to="/investor-list">
              <span className="material-symbols-rounded filled-icon">
                task
              </span>
              <li >Investor Request List</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/become-entrepreneur">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Become Entrepreneur</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/partner-with-us">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Partner With Us</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/schemes-Request">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Plans Request</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/Schedule-Meeting-Registration">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Schedule Meeting</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/ServiceRequest">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Service Request</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Employee Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li >Employee Management</li>
        </a>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink1"
        >
          <li>
            <NavLink to="/admin/employee-clock-in-out">
              <span className="material-symbols-rounded filled-icon">
                dashboard
              </span>
              <li >Employee Clock In/Out</li>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="dropdown-item"
              to="/EmployeeRoleManagement"
            >
              <div className="figure">
                <img className="pi-icon" src={AddI4} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover4}
                  alt=""
                />
              </div>
              <li >Employee Role</li>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="dropdown-item"
              to="/EmployeeManagement"
            >
              <div className="figure">
                <img className="pi-icon" src={AddI3} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover3}
                  alt=""
                />
              </div>
              <li >Employee Management</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/LeaveModule">
              <div className="figure">
                <img className="pi-icon" src={SI1} alt="" />
                <img className="pi-icon image-hover" src={SIhover1} alt="" />
              </div>
              <li >Leave Management</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Admin Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li>Admin Management</li>
        </a>

        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink"
        >
          <li>
            <NavLink
              className="dropdown-item"
              to="/AdminRoleManagement"
            >
              <div className="figure">
                <img className="pi-icon" src={AddI4} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover4}
                  alt=""
                />
              </div>
              <li >Admin Role</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/AdminManagement">
              <div className="figure">
                <img className="pi-icon" src={AddI5} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover5}
                  alt=""
                />
              </div>
              <li >Admin Management</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Customer Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li >Customer Management</li>
        </a>

        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink"
        >
          <li>
            <NavLink to="/UserManagement">
              <span className="material-symbols-rounded filled-icon">
                person
              </span>
              <li >User Management</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/NewsLetter">
              <div className="figure">
                <img className="pi-icon" src={AddI4} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover4}
                  alt=""
                />
              </div>
              <li >Newsletter</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/ContactUsers">
              <span className="material-symbols-rounded filled-icon">
                person
              </span>
              <li >Contact us form</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Task Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li >Task Management</li>
        </a>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink1"
        >
          <li>
            <NavLink to="/TaskList">
              <span className="material-symbols-rounded filled-icon">
                task
              </span>
              <li >Global Task List</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/MyTask">
              <span className="material-symbols-rounded filled-icon">
                task
              </span>
              <li >My Task</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* CMS Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink6"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li>CMS</li>
        </a>

        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink6"
        >
          <li>
            <NavLink className="dropdown-item" to="/Main">
              <div className="figure">
                <img className="pi-icon" src={AddI4} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover4}
                  alt=""
                />
              </div>
              <li >Main</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/Level1">
              <div className="figure">
                <img className="pi-icon" src={AddI5} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover5}
                  alt=""
                />
              </div>
              <li >Level 1</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/Level2">
              <div className="figure">
                <img className="pi-icon" src={AddI5} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover5}
                  alt=""
                />
              </div>
              <li >Level 2</li>
            </NavLink>
          </li>
          <li>
            <NavLink className="dropdown-item" to="/Content">
              <div className="figure">
                <img className="pi-icon" src={AddI5} alt="" />
                <img
                  className="pi-icon image-hover"
                  src={Addhover5}
                  alt=""
                />
              </div>
              <li >Content</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Leads Management */}
      <div className="dropdown drop-admin-nav">
        <a
          className=" dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink6"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <div className="figure">
            <img className="pi-icon" src={AddI4} alt="" />
            <img
              className="pi-icon image-hover"
              src={Addhover4}
              alt=""
            />
          </div>
          <li>Leads Management</li>
        </a>

        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuLink6"
        >
          <li>
            <NavLink to="/Lead">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >WalkIn Leads</li>
            </NavLink>
          </li>
          <li>
            <NavLink to="/website-leads">
              <div className="figure">
                <img className="pi-icon" src={AddI1} alt="" />
                <img className="pi-icon image-hover" src={Addhover1} alt="" />
              </div>
              <li >Website Leads</li>
            </NavLink>
          </li>
        </ul>
      </div>
      {/* Subscription Management */}
      <NavLink to="/ServicePackage">
        <div className="figure">
          <img className="pi-icon" src={AddI2} alt="" />
          <img className="pi-icon image-hover" src={Addhover2} alt="" />
        </div>
        <li >Subscription Management</li>
      </NavLink>
      {/* Job Management */}
      <NavLink to="/JobManagement">
        <div className="figure">
          <img className="pi-icon" src={AddI4} alt="" />
          <img className="pi-icon image-hover" src={Addhover4} alt="" />
        </div>
        <li >Job Management</li>
      </NavLink>
      {/* Purchase Management */}
      <NavLink to="/History">
        <div className="figure">
          <img className="pi-icon" src={AI3} alt="" />
          <img className="pi-icon image-hover" src={AIhover3} alt="" />
        </div>
        <li >Purchase History</li>
      </NavLink>
      {/* Profile Management */}
      <NavLink to="/Profile">
        <div className="figure">
          <img className="pi-icon" src={AI4} alt="" />
          <img className="pi-icon image-hover" src={AIhover4} alt="" />
        </div>
        <li >Profile Management</li>
      </NavLink>
      <a
        href="javascript:void(0);"
        className="logout_text"
        onClick={(e) => {
          Swal.fire({
            title: "Are you sure?",
            text: "You want to logout !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#540326",
            cancelButtonColor: "#e08638",
            confirmButtonText: "Yes, logout",
          }).then((result) => {
            if (result.isConfirmed) {
              Logout(e);
            }
          });
        }}
        id="logout-hover"
      >
        <span className="material-symbols-rounded">logout</span>
        <li>Logout</li>
      </a>
    </ul>
  );
}
