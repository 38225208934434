import React, { useEffect, useState } from 'react';
import './ServiceBody.css'; // Import CSS file for styling
import { Helmet } from 'react-helmet';
import {
    EmailValidation,
    NonEmptyValidation,
    PhoneNumberValidation,
    StringValidation,
} from "../../../Store/validate";
import { UserServiceRequest } from '../../../Api/Api';
import { showToastErrorMsg, showToastSuccessMsg } from '../../../Store/toastMsg';
import PropertyAuctionList from '../../Audit/PropertyAuctionList';
import GoldAuctionList from '../../Audit/GoldAuctionList';
import VechileAuctionList from '../../Audit/VechileAuctionList';
import { useLocation } from 'react-router-dom';

export default function ServiceBody({ contentProps, stateProps }) {

    const locationSearch = useLocation();
    const [selectedTab, setSelectedTab] = useState("");
    const [selectedSubTab, setSelectedSubTab] = useState("check");
    const [selectedSubTabCheck, setSelectedSubTabCheck] = useState("check");
    useEffect(() => {
        window.scrollTo(0, 0);
        const search = locationSearch.search;
        const tab = new URLSearchParams(search).get("tab");
        const subtab = new URLSearchParams(search).get("subtab");

        if (tab) {
            setSelectedTab(tab);
            setSelectedSubTab(subtab.split(" ")[0]);
            setSelectedSubTabCheck(subtab);
            // console.log("selectedSubTab", selectedSubTab)
            // console.log("setSelectedSubTabCheck", selectedSubTabCheck)
        }
    }, []);

    const [serviceReqValidation1, setServiceReqValidation1] = useState({});
    const [serviceReq1, setServiceReq1] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [form, setForm] = useState(false);

    let cat = localStorage?.getItem("category")?.split("?");

    const setServiceReqValue1 = (key, value) => {
        setServiceReq1({ ...serviceReq1, [key]: value });
        if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
    };
    const setServiceReqValidationValue1 = (key, value) => {
        setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
    };

    const handleSubmitServiceform = async (e) => {
        e.preventDefault();
        let Payload;
        setIsButtonDisabled(true);
        if (!stateProps?.auth?.isLoggedIn) {
            Payload = {
                name: serviceReq1.name,
                email: serviceReq1.email,
                subject: serviceReq1.subject || '',  // Ensure subject is an empty string if null or undefined
                question: serviceReq1.question || '',  // Ensure question is an empty string if null or undefined
                mobileNumber: serviceReq1.mobileNumber,
                type: "service",
                servicesCategory: contentProps[0]?.mainServiceId?.title || '',
                servicesSubcategory: contentProps[0]?.subServiceL1Id?.title || '',
                servicesTitle: contentProps[0]?.subServiceL2Id?.title || '',
            };
        } else {
            Payload = {
                subject: serviceReq1.subject || '',  // Ensure subject is an empty string if null or undefined
                question: serviceReq1.question || '',  // Ensure question is an empty string if null or undefined
                type: "service",
                servicesCategory: contentProps[0]?.mainServiceId?.title || '',
                servicesSubcategory: contentProps[0]?.subServiceL1Id?.title || '',
                servicesTitle: contentProps[0]?.subServiceL2Id?.title || '',
                userId: stateProps?.auth?.auth?._id
            };
        }

        const response = await UserServiceRequest(Payload);
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setServiceReq1({});
            setForm(false);
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
        }
        setIsButtonDisabled(false);
    };

    return (
        <div className="container py-5">
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {window.location.href.substring(window.location.href.indexOf('=') + 1, window.location.href.indexOf('&')).replace(/%20/g, ' ')}
                    | {decodeURI(window.location.href.substring(window.location.href.indexOf('&') + 8, window.location.href.lastIndexOf('&')).replace(/%20/g, ' '))}
                    | DALMaf Private Limited
                </title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content={contentProps?.content?.substring(0, 100)} />
                <meta name="keywords" content={contentProps?.content?.substring(0, 100)} />
                <meta name="author" content="DALMaf" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            {

                selectedSubTab === "Auctions" && selectedSubTabCheck.indexOf("Property") !== -1 ? <PropertyAuctionList /> :
                    selectedSubTab === "Auctions" && selectedSubTabCheck.indexOf("Gold") !== -1 ? <GoldAuctionList /> :
                        selectedSubTab === "Auctions" && selectedSubTabCheck.indexOf("Vehicle") !== -1 ? <VechileAuctionList /> :
                            <div className="row">
                                <div className="col-lg-12 col-md-12 non-sticky">
                                    {contentProps?.map((item, index) => (
                                        <div key={index}>
                                            {item?.content ? (
                                                <p dangerouslySetInnerHTML={{ __html: item?.content }}></p>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                                {/* <div className="col-lg-4 col-md-4">
                                    <div className="request-callback-form">
                                    <div className="request-callback-form sticky-top">
                                        <h5>REQUEST A CALL BACK</h5>
                                        <form onSubmit={handleSubmitServiceform}>
                                            <div className="mb-3">
                                                <input
                                                    disabled={stateProps?.auth?.auth?.name}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Your Name"
                                                    aria-invalid={Boolean(serviceReqValidation1?.name?.status === false)}
                                                    value={stateProps?.auth?.auth?.name || serviceReq1?.name || ''}
                                                    onChange={(e) => setServiceReqValue1("name", e.target.value)}
                                                    onBlur={(e) => setServiceReqValidationValue1("name", StringValidation(e.target.value))}
                                                />
                                                <small className="text-danger">
                                                    {serviceReqValidation1?.name?.message ? `Name ${serviceReqValidation1?.name?.message}` : ""}
                                                </small>
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    disabled={stateProps?.auth?.auth?.mobileNumber}
                                                    type="tel"
                                                    maxLength={10}
                                                    className="form-control"
                                                    placeholder="Enter Your Mobile No"
                                                    aria-invalid={Boolean(serviceReqValidation1?.mobileNumber?.status === false)}
                                                    value={stateProps?.auth?.auth?.mobileNumber || serviceReq1?.mobileNumber || ''}
                                                    onChange={(e) => setServiceReqValue1("mobileNumber", e.target.value)}
                                                    onBlur={(e) => setServiceReqValidationValue1("mobileNumber", PhoneNumberValidation(e.target.value))}
                                                />
                                                <small className="text-danger">
                                                    {serviceReqValidation1?.mobileNumber?.message ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}` : ""}
                                                </small>
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    disabled={stateProps?.auth?.auth?.email}
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter Your E-Mail ID"
                                                    aria-invalid={Boolean(serviceReqValidation1?.email?.status === false)}
                                                    value={stateProps?.auth?.auth?.email || serviceReq1?.email?.toLowerCase() || ''}
                                                    onChange={(e) => setServiceReqValue1("email", e.target.value.toLowerCase())}
                                                    onBlur={(e) => setServiceReqValidationValue1("email", EmailValidation(e.target.value.toLowerCase()))}
                                                />
                                                <small className="text-danger">
                                                    {serviceReqValidation1?.email?.message ? `Email ${serviceReqValidation1?.email?.message}` : ""}
                                                </small>
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Your Questions"
                                                    value={serviceReq1?.question || ''}
                                                    onChange={(e) => setServiceReqValue1("question", e.target.value)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <textarea
                                                    rows="3"
                                                    className="form-control"
                                                    placeholder="Enter Your Comments/Needs"
                                                    value={serviceReq1?.subject || ''}
                                                    onChange={(e) => setServiceReqValue1("subject", e.target.value)}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    disabled={isButtonDisabled}
                                                >
                                                    {isButtonDisabled ? (
                                                        <>
                                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                            Submiting...
                                                        </>
                                                    ) : (
                                                        'Get Started'
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                </div> */}
                            </div>
            }


        </div>
    );
}
